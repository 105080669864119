<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card :title="$t('Messages')">
      <b-row
        align-h="center"
      >
        <b-col
          cols="12"
        >
          <b-table
            sticky-header="1000px"
            no-border-collapse
            responsive
            striped
            hover
            :per-page="pageSize"
            :fields="messagesFields"
            :items="messages"
            :tbody-tr-class="rowClass"
            @row-clicked="onRowSelected"
          >
            <template #cell(text)="data">
              {{ data.text }}
              <span v-if="data.item.text.length < 10">
                {{
                  data.item.text
                }}
              </span>
              <span v-else>
                {{ data.item.text.slice(0,29) + '...' }}
              </span>
            </template>
            <template #cell(create_at)="data">
              <span>{{
                new Date(data.item["create_at"]).toLocaleString($i18n.locale)
              }}
              </span>
            </template>
          </b-table>
          <div
            v-if="!messages.length"
            class="text-center"
          >
            {{ $t('no_item_exsist') }}
          </div>
          <!--  -->

        </b-col>
        <b-pagination
          v-if="totalMessages > pageSize"
          v-model="currentPage"
          :total-rows="totalMessages"
          :per-page="pageSize"
          align="fill"
          size="sm"
          class="mt-2 mb-1"
        />
      </b-row>
    </b-card>
    <!-- <MessageModal :id="selectedMessage" /> -->
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
  },
  data() {
    return {
      modalShow: false,
      show: true,
      selectedMessage: '',
      selectedMessageId: null,
      currentPage: 1,
      pageSize: 10,
    }
  },

  computed: {
    messagesFields() {
      return [
        { key: 'title', label: this.$t('title') },
        { key: 'subtitle', label: this.$t('subtitle') },
        { key: 'message', label: this.$t('Message') },
        { key: 'create_at', label: this.$t('create_at') },
      ]
    },
    messages() {
      return this.$store.state.Messaging.messages
    },
    totalMessages() {
      return this.$store.state.Messaging.totalMessages
    },
    selectedMessageModal() {
      return this.$store.state.Messaging.selectedMessage
    },
    unReadMessages() {
      return this.$store.state.Messaging.unReadMessages
    },
  },

  watch: {
    currentPage() {
      this.getAllMessages()
    },

    selectedMessageModal: {
      handler() {
        this.getAllMessages()
      },
      deep: true,
    },

    unReadMessages: {
      handler() {
        // this.currentPage = 1
        this.getAllMessages()
      },
      deep: true,
    },
  },

  async created() {
    await this.getAllMessages()
  },

  methods: {
    ...mapActions('Messaging', ['openMessageModal', 'setAllMessages']),
    async onRowSelected({ id }) {
      this.openMessageModal(id)
    },

    rowClass(item, type) {
      if (item && type === 'row') {
        if (!item.is_read) {
          return 'un-read-message'
        }
      }
      return null
    },

    async getAllMessages() {
      this.show = true
      await this.setAllMessages(
        {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
        },
      )
      this.show = false
    },

    handleOk() {
      this.modalShow = false
      this.getAllMessages()
    },
  },
}
</script>

<style>
.un-read-message {
  background-color: #ffe1e1 !important;
}
</style>
